const switchBox = document.querySelector('.checkbox__switch--js');
const tariff = document.querySelector('.tariff--js');
switchBox.addEventListener('change', function() {
    if (this.checked) {
        switchBox.nextElementSibling.classList.add('_active')
        switchBox.previousElementSibling.classList.remove('_active')
        tariff.querySelector('[data-tariff="1"]').classList.add('_active');
        tariff.querySelector('[data-tariff="2"]').classList.remove('_active');
      } else {
        switchBox.nextElementSibling.classList.remove('_active')
        switchBox.previousElementSibling.classList.add('_active')
        tariff.querySelector('[data-tariff="1"]').classList.remove('_active');
        tariff.querySelector('[data-tariff="2"]').classList.add('_active');
      }
})