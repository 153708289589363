// import simpleParallax from 'simple-parallax-js';

// Form
import './form/form'


// Hamburger
import './hamburger/hamburger'
import './overlay/overlay'
import './welcome/welcome'
import './tariff/tariff'
import './equipment/equipment'
import './documents/documents'
import './modal/modal'



// import './header/header'
